// app global css in SCSS form
.border-radius-inherit {
  border-radius: inherit;
}

.last-border-radius-inherit:last-of-type {
  border-radius: inherit;
}

.q-dialog__backdrop {
  backdrop-filter: blur(1px);
}

.q-dialog__inner > .q-card {
  border-radius: 15px;
  padding: 12px
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    z-index: 1;
    scrollbar-width: auto;
    scrollbar-color: rgba(186, 186, 186, 0.533);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    z-index: 1;
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    z-index: 1;
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    z-index: 1;
    background-color: rgba(186, 186, 186, 0.533);
    border-radius: 10px;
    border: 0px solid #ffffff;
  }